a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}
.navbar-brand img {
  height: 50px;
}
html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .navbar-brand img {
    height: 40px;
  }
}
nav {
  height: 75px;
  background-color: white;
}
.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


