a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}
.navbar-brand img {
  height: 50px;
}
html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .navbar-brand img {
    height: 40px;
  }
}
nav {
  height: 75px;
  background-color: white;
}
.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}



.box {
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 32px;
}
@media (max-width: 425px) {
    .query-view {
      padding: 0;
    }
    .box {
        padding: 16px;
    }
}
@media (max-width: 365px) {
    #g-recaptcha {
        -webkit-transform: scale(0.85);
                transform: scale(0.85);
        -webkit-transform-origin: right top;
                transform-origin: right top;
    }
}
*{zoom: 1;}
body {
    background: #f4f5f9;
}
@page{
    margin: 15px 25px;

    size: auto;
}
.max-w-100  {
    max-width: 100%;
}
@media print {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    .navbar,
    p, a,
    .td-name,
    td {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: 'Open Sans', Helvetica, Arial, sans-serif;
        line-height: 1.4em;
    }
    hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .print-show,
    #printWrap{
        display: block;
        padding: 0 0 0 2px;
    }
    .print-hide {
        display: none;
    }
    /* RESET BOOTSTRAP PRINT CSS */
    body {
        min-width: unset!important;
    }
}

.grow {
    position: relative;
    width: 200px;
    height: 200px;
    display: inline-block;
}
.grow img {
    width: 100px;
    height: 100px;
    margin: 50px;
    z-index: 2;
    position: absolute;
    left: 0;
}

.loader {
    content: '';
    z-index: 1;
    width: 200px;
    height: 200px;
    opacity: 0;
    left: 0;
    border-radius: 50%;
    position: absolute;
}

.grow .loader-1 {
    background: #ffc10771;
    -webkit-animation: spinner-grow 2s linear infinite;
            animation: spinner-grow 2s linear infinite;
}

.grow .loader-2 {
    background: #ffc1079d;
    -webkit-animation: spinner-grow 2s linear infinite;
            animation: spinner-grow 2s linear infinite;
    -webkit-animation-delay: .4s;
            animation-delay: .4s;
}

.grow .loader-3 {
    background: #ffc1079d;
    -webkit-animation: spinner-grow 2s linear infinite;
            animation: spinner-grow 2s linear infinite;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

body.landing {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.index-logo {
    width: 100%;
}

.icon-biling::before {
    content: "\EA0F";
}

.icon-book::before {
    content: "\EA11";
}
