body.landing {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.index-logo {
    width: 100%;
}

.icon-biling::before {
    content: "\EA0F";
}

.icon-book::before {
    content: "\EA11";
}