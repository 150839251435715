*{zoom: 1;}
body {
    background: #f4f5f9;
}
@page{
    margin: 15px 25px;

    size: auto;
}
.max-w-100  {
    max-width: 100%;
}
@media print {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    .navbar,
    p, a,
    .td-name,
    td {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: 'Open Sans', Helvetica, Arial, sans-serif;
        line-height: 1.4em;
    }
    hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .print-show,
    #printWrap{
        display: block;
        padding: 0 0 0 2px;
    }
    .print-hide {
        display: none;
    }
    /* RESET BOOTSTRAP PRINT CSS */
    body {
        min-width: unset!important;
    }
}

.grow {
    position: relative;
    width: 200px;
    height: 200px;
    display: inline-block;
}
.grow img {
    width: 100px;
    height: 100px;
    margin: 50px;
    z-index: 2;
    position: absolute;
    left: 0;
}

.loader {
    content: '';
    z-index: 1;
    width: 200px;
    height: 200px;
    opacity: 0;
    left: 0;
    border-radius: 50%;
    position: absolute;
}

.grow .loader-1 {
    background: #ffc10771;
    animation: spinner-grow 2s linear infinite;
}

.grow .loader-2 {
    background: #ffc1079d;
    animation: spinner-grow 2s linear infinite;
    animation-delay: .4s;
}

.grow .loader-3 {
    background: #ffc1079d;
    animation: spinner-grow 2s linear infinite;
    animation-delay: 1s;
}
