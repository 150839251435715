.box {
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 32px;
}
@media (max-width: 425px) {
    .query-view {
      padding: 0;
    }
    .box {
        padding: 16px;
    }
}
@media (max-width: 365px) {
    #g-recaptcha {
        transform: scale(0.85);
        transform-origin: right top;
    }
}